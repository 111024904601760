import Icon from "react-icons-kit";
import styled from "styled-components";

const SocialIconBarWrapper = styled.div``;

export const SocialIcon = styled(Icon)<{ "data-light"?: boolean }>`
  margin: 0 0.2rem;
  border: 1px solid
    ${props =>
      props["data-light"]
        ? props.theme.colors.primary
        : props.theme.colors.border};
  border-radius: 1000px;
  padding: 1rem;

  color: ${props =>
    props["data-light"] ? props.theme.colors.primary : "white"};
`;

export default SocialIconBarWrapper;
