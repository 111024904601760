import React, { FC } from "react";
import styled from "styled-components";
import {
  compose,
  fontFamily,
  FontFamilyProps,
  fontWeight,
  FontWeightProps,
  letterSpacing,
  LetterSpacingProps,
  lineHeight,
  LineHeightProps,
  textAlign,
  TextAlignProps
} from "styled-system";
import { base, BaseProps, themed } from "./base";

interface TextProps
  extends BaseProps,
    FontFamilyProps,
    FontWeightProps,
    TextAlignProps,
    LineHeightProps,
    LetterSpacingProps {
  as?: "h1" | "h2" | "h3" | "h4" | "p" | "span";
}

const TextWrapper: FC<TextProps> = styled("div")<TextProps>(
  compose(
    base,
    fontFamily,
    fontWeight,
    textAlign,
    lineHeight,
    letterSpacing,
    themed("Text")
  )
);

const Text: FC<TextProps & { content: string; className?: string }> = ({
  content,
  ...props
}) => <TextWrapper {...props}>{content}</TextWrapper>;

export default Text;
