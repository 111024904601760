import React, { FC } from "react";
import { Button as RebassButton, ButtonProps } from "rebass";
import Spinner from "./Spinner";

type Props = {
  loading?: boolean;
};

const Button: FC<ButtonProps & Props> = ({
  children,
  loading = false,
  disabled,
  ...rest
}) => {
  return (
    <RebassButton
      borderRadius={8}
      color={"white"}
      bg={disabled ? "border" : "primary"}
      disabled={disabled}
      width={["80%", "280px"]}
      fontSize={[2, 3]}
      css={`
        cursor: pointer;
        letter-spacing: 2px;
      `}
      {...rest}
    >
      {loading ? <Spinner /> : children || "送出"}
    </RebassButton>
  );
};

export default Button;
