import { GatsbyLinkProps } from "gatsby";
import React, { FC } from "react";
import styled, { css } from "styled-components";
import {
  alignItems,
  AlignItemsProps,
  display,
  flexDirection,
  FlexDirectionProps,
  flexWrap,
  FlexWrapProps,
  justifyContent,
  JustifyContentProps
} from "styled-system";
import { base, BaseProps, themed } from "./base";

interface FlexBoxProps
  extends FlexWrapProps,
    FlexDirectionProps,
    AlignItemsProps,
    JustifyContentProps {
  flexBox?: boolean;
}

const BoxWrapper: FC<FlexBoxProps> = styled("div")<
  {
    flexBox?: boolean;
  } & FlexBoxProps
>(
  base,
  themed("Box"),
  props =>
    props.flexBox &&
    css(
      { display: "flex" },
      flexWrap,
      flexDirection,
      alignItems,
      justifyContent,
      display,
      themed("FlexBox")
    )
);

const Box: FC<BoxProps & FlexBoxProps> = ({
  children,
  as = "div",
  ...props
}) => <BoxWrapper {...{ as, ...props }}>{children}</BoxWrapper>;

export default Box;

export interface BoxProps extends BaseProps {
  as?:
    | "div"
    | "article"
    | "section"
    | "address"
    | "header"
    | "footer"
    | "nav"
    | "main"
    | GatsbyLinkProps<any>;
  className?: string;
}
