import styled from "styled-components";
import {
  alignItems,
  AlignItemsProps,
  borderRadius,
  BorderRadiusProps,
  boxShadow,
  BoxShadowProps,
  color,
  ColorStyleProps,
  display,
  DisplayProps,
  flexDirection,
  FlexDirectionProps,
  flexWrap,
  FlexWrapProps,
  height,
  HeightProps,
  justifyContent,
  JustifyContentProps,
  maxWidth,
  MaxWidthProps,
  space,
  SpaceProps,
  width,
  WidthProps
} from "styled-system";

export interface NavbarProps
  extends AlignItemsProps,
    BorderRadiusProps,
    BoxShadowProps,
    ColorStyleProps,
    DisplayProps,
    FlexDirectionProps,
    FlexWrapProps,
    HeightProps,
    JustifyContentProps,
    SpaceProps,
    WidthProps,
    MaxWidthProps {}

const NavbarStyle = styled("nav")<NavbarProps>`
  /* Navbar default style goes here */
  display: flex;
  align-items: center;
  min-height: 56px;
  
  /* Style system supported prop */
  ${display}
  ${alignItems}
  ${justifyContent}
  ${flexDirection}
  ${flexWrap}
  ${width}
  ${height}
  ${color}
  ${space}
  ${boxShadow}
  ${borderRadius}
  ${maxWidth}
`;

NavbarStyle.displayName = "NavbarStyle";

export default NavbarStyle;
