import RcDrawer from "rc-drawer";
import "rc-drawer/assets/index.css";
import React, { FC, Fragment } from "react";

type Props = {
  width?: string;
  className?: string;
  closeButton?: any;
  closeButtonStyle?: any;
  drawerHandler?: any;
  toggleHandler?: any;
  open: boolean;
  placement?: "left" | "right" | "top" | "bottom";
  handler?: boolean;
  level?: any;
};

const Drawer: FC<Props> = ({
  className,
  children,
  closeButton,
  closeButtonStyle,
  drawerHandler,
  toggleHandler,
  open,
  ...props
}) => {
  // Add all classs to an array
  const addAllClasses = ["reusecore__drawer"];

  // className prop checking
  if (className) {
    addAllClasses.push(className);
  }

  return (
    <Fragment>
      <RcDrawer
        open={open}
        onMaskClick={toggleHandler}
        className={addAllClasses.join(" ")}
        {...props}
      >
        <div
          className="reusecore-drawer__close"
          onClick={toggleHandler}
          style={closeButtonStyle}
        >
          {closeButton}
        </div>
        {children}
      </RcDrawer>
      <div
        className="reusecore-drawer__handler"
        style={{ display: "inline-block" }}
        onClick={toggleHandler}
      >
        {drawerHandler}
      </div>
    </Fragment>
  );
};

Drawer.defaultProps = {
  width: "300px",
  handler: false,
  level: null
};

export default Drawer;
