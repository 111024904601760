import React, { useContext, useReducer } from "react";

const initialState = {
  isOpen: false
};

function reducer(state, action) {
  switch (action.type) {
    case "TOGGLE":
      return {
        ...state,
        isOpen: !state.isOpen
      };
    default:
      return state;
  }
}
export const DrawerContext = React.createContext({
  dispatch: null,
  state: {
    isOpen: false
  }
});

export const DrawerProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <DrawerContext.Provider value={{ state, dispatch }}>
      {children}
    </DrawerContext.Provider>
  );
};

export const useDrawer = () => {
  const context = useContext(DrawerContext);

  if (context === null)
    throw new Error("useDrawer must be used with DrawerProvider");

  return context;
};
