import { graphql, useStaticQuery } from "gatsby";
import React, { FC } from "react";
import Container from "../../components/Container";
import Box from "../../components/elements/Box";
import Text from "../../components/elements/Text";
import { contacts } from "../../lib/config";
import SocialIconBar from "../SocialIconBar";
import FooterWrapper from "./footer.style";

const Footer: FC = ({}) => {
  const { site } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  return (
    <FooterWrapper>
      <Container noGutter mobileGutter width="1200px">
        <Box
          flexBox
          flexWrap="wrap"
          alignItems="center"
          justifyContent="center"
          mb={4}
        >
          <SocialIconBar size={36} width={[1, 1 / 3]} />
        </Box>
        <Box
          flexBox
          flexWrap="wrap"
          alignItems="center"
          justifyContent="center"
          mb={2}
        >
          {Object.keys(contacts).map(
            (k, i) =>
              contacts[k].label && (
                <a
                  rel="noreferrer"
                  className="footer__contact"
                  href={contacts[k].link}
                  target="_blank"
                  key={`footer-contact-${i}`}
                  css={`
                    margin: 0 1rem;
                  `}
                >
                  <Text
                    mx={[0, 2]}
                    my={[1, 0]}
                    content={`${contacts[k].label}: ${contacts[k].text}`}
                  ></Text>
                </a>
              )
          )}
        </Box>
        <Text
          textAlign="center"
          content={`Copyright © 2019 ${site.siteMetadata.title} 保留一切權利。`}
        ></Text>
      </Container>
    </FooterWrapper>
  );
};

export default Footer;

Footer.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: "wrap",
    mx: -1
  }
};
