import React, { FC } from "react";
import {
  Box,
  BoxProps,
  FlexProps,
  Heading,
  HeadingProps,
  Text,
  TextProps
} from "rebass";
import styled from "styled-components";

type SectionProps = {
  fluid?: boolean;
  maxWidth?: string;
  title?: string;
  divider?: boolean;
  noGutter?: boolean;
};

const Section: FC<SectionProps & FlexProps> = ({
  children,
  fluid = false,
  maxWidth = "1200px",
  title,
  divider,
  noGutter = false,
  ...rest
}) => (
  <Box
    width={1}
    mx={["auto"]}
    pt={["2rem", "4rem"]}
    pb={["2rem", "4rem"]}
    {...rest}
  >
    {title && <SectionHeader divider={divider}>{title}</SectionHeader>}

    <Box
      mx="auto"
      css={`
        max-width: ${fluid ? "100%" : maxWidth};
      `}
    >
      {/* <Fade> */}
      <>{children}</>
      {/* </Fade> */}
    </Box>
  </Box>
);

export default Section;

type SectionHeaderProps = {
  divider?: boolean;
  headerProps?: {};
  textAlign?: string[];
};

export const SectionHeader: FC<HeadingProps & SectionHeaderProps> = ({
  children,
  divider = true,
  textAlign = ["center"],
  headerProps,
  ...rest
}) => {
  const leftAlign = Boolean(textAlign.includes("left"));

  return (
    <>
      <Heading
        width={leftAlign ? [1] : ["90%", 3 / 4, 2 / 4]}
        mb={[2]}
        mx="auto"
        // px={[0, 2, "4rem"]}
        fontSize={[6, 7]}
        fontWeight="bold"
        color="success"
        textAlign={textAlign}
        {...headerProps}
      >
        {children}
      </Heading>
      {divider && (
        <Divider
          mb={[4]}
          bg="info"
          mx={leftAlign ? ["auto", "auto", 0] : ["auto"]}
          width={["4rem"]}
          {...rest}
        />
      )}
    </>
  );
};

const Divider: FC<BoxProps> = styled(Box)`
  height: 4px;
`;
