import React from "react";
import { Icon } from "react-icons-kit";
import { spinner } from "react-icons-kit/fa/spinner";
import { keyframes } from "styled-components";

const spin = keyframes({
  "0%": { transform: "rotate(0deg)" },
  "100%": { transform: "rotate(360deg)" }
});

export default function Spinner(props) {
  return (
    <Icon
      icon={spinner}
      css={`
        animation: ${spin} 1s linear infinite;
      `}
      aria-label="loading"
      {...props}
    />
  );
}
