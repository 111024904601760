import { graphql, navigate, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import { Icon } from "react-icons-kit";
import { androidClose } from "react-icons-kit/ionicons/androidClose";
import { Flex } from "rebass";
import Button from "../../components/Button";
import Drawer from "../../components/Drawer";
import Box from "../../components/elements/Box";
import HamburgMenu from "../../components/HamburgMenu";
import Navbar from "../../components/Navbar";
import ScrollSpyMenu from "../../components/ScollSpyMenu";
import { useDrawer } from "../../context/drawer.context";
import { contacts } from "../../lib/config";
import { theme } from "../../lib/theme";
import SocialIconBar from "../SocialIconBar";
import HeaderWrapper, { LogoBox, Menu } from "./header.style";

const MENU_ITEMS = [
  // { label: "首頁", path: "/" },
  { label: "希臘簡介", path: "/about-greece/", staticLink: true },
  { label: "移民須知", path: "/need-to-know/", staticLink: true },
  { label: "樓市走勢", path: "/market-trend/", staticLink: true },
  { label: "買賣費用", path: "/fee/", staticLink: true },
  { label: "精選樓盤", path: "/properties/", staticLink: true },
  // { label: "樓宇轉按", path: "/refinancing/" },
  { label: "常見問題", path: "/faq/", staticLink: true },
  // { label: "移民報導", path: "/news/" },
  // { label: "考察/講座", path: "/inspection-lecture/" },
  { label: "關於我們", path: "/about-us/", staticLink: true }
];

const Header = ({ transparent = true }) => {
  const { state, dispatch } = useDrawer();
  const { headerLogo } = useStaticQuery(graphql`
    {
      headerLogo: file(relativePath: { eq: "header-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);

  const toggleHandler = () => {
    dispatch({
      type: "TOGGLE"
    });
  };

  return (
    <HeaderWrapper className="saas_navbar" transparent={transparent}>
      <Navbar mx={[0, 0, "auto"]} px={[1, 2, 2]} maxWidth={[1200]}>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          width={[1, 1, 1 / 3]}
        >
          <LogoBox
            onClick={() => {
              navigate("/");
            }}
            width={1}
            height="100%"
            mr="auto"
            maxWidth={["100%", "250px"]}
            css={`
              cursor: pointer;
            `}
          >
            <Img fluid={headerLogo.childImageSharp.fluid} />
          </LogoBox>
        </Flex>

        <Menu alignItems="center" display={["none", "none", "flex"]}>
          <ScrollSpyMenu
            className="main_menu"
            menuItems={MENU_ITEMS}
            offset={-70}
          />

          <Button
            as="a"
            href={contacts.whatsapp.link}
            rel="noopener"
            target="_blank"
            width={"120px"}
            ml={[2]}
            variant="small"
            bg="warning"
            color="info"
            fontSize={[2]}
          >
            免費諮詢
          </Button>
        </Menu>

        <Menu alignItems="center" display={["flex", "flex", "none"]}>
          <Button
            as="a"
            href={contacts.whatsapp.link}
            target="_blank"
            rel="noopener"
            width={"100px"}
            ml={["auto"]}
            mr={[0, 2]}
            variant="tiny"
            bg="warning"
            color="info"
            fontSize={[1]}
            // css={`
            //   max-width: 120px;
            // `}
          >
            免費諮詢
          </Button>
          <Drawer
            placement="right"
            drawerHandler={
              <HamburgMenu
                barColor={transparent ? "#fff" : theme.colors.primary}
              />
            }
            open={state.isOpen}
            toggleHandler={toggleHandler}
          >
            <Icon
              onClick={toggleHandler}
              icon={androidClose}
              size={32}
              css={`
                color: #c2c2c2;
                position: absolute;
                top: 1rem;
                right: 1rem;
              `}
            />
            <ScrollSpyMenu
              className="mobile_menu"
              menuItems={MENU_ITEMS}
              drawerClose={true}
              offset={-100}
            />

            <div
              css={`
                position: absolute;
                left: 1rem;
                right: 1rem;
                bottom: 1.5rem;
              `}
            >
              <Box
                flexBox
                justifyContent="center"
                alignItems="center"
                mb={["1rem"]}
              >
                <SocialIconBar light size={26}></SocialIconBar>
              </Box>

              <Button
                as="a"
                href={contacts.whatsapp.link}
                target="_blank"
                rel="noopener"
                width={"90%"}
                mx={2}
                variant="primary"
                fontSize={[2]}
              >
                免費諮詢
              </Button>
            </div>
          </Drawer>
        </Menu>
      </Navbar>
    </HeaderWrapper>
  );
};

export default Header;
