import { facebook } from "react-icons-kit/fa/facebook";
import { phone } from "react-icons-kit/fa/phone";
import { whatsapp } from "react-icons-kit/fa/whatsapp";
import { youtube } from "react-icons-kit/fa/youtube";

export const serverUrl =
  process.env.NODE_ENV === "production"
    ? "https://api.thegreeceproperty.com"
    : "http://localhost:4000";

export const googleMapKey = "AIzaSyBceN8Lff1BoV6_eWMGrj6tDvbVtAOaS-w";

export const contacts = {
  phone: {
    label: "電話",
    icon: phone,
    text: "+852 2242 2294",
    link: `tel:+85222422294`
  },
  whatsapp: {
    label: "Whatsapp",
    icon: whatsapp,
    text: "+852 5600 6138",
    link: `https://wa.me/85256006138`
  },
  youtube: {
    icon: youtube,
    link: `https://www.youtube.com/channel/UC1osvq8ag-eVnLhJgzTessg`
  },
  facebook: {
    icon: facebook,
    link: `https://www.facebook.com/pages/category/Consulting-Agency/%E5%B8%8C%E8%87%98%E6%88%BF%E7%94%A2%E6%8A%95%E8%B3%87-1261523723996376/`
  }
  // wechat: {
  //   label: "Wechat",
  //   icon: wechat,
  //   text: "+852 5600 6138",
  //   link: `weixin://dl/chat?+85256006138`
  // }
  // address: {
  //   icon: locationArrow,
  //   text: "香港中環干諾道中30-32號莊士大廈11樓"
  // }
};
