import { Modal } from "@redq/reuse-modal";
import "@redq/reuse-modal/es/index.css";
import React, { FC } from "react";
import Sticky from "react-stickynode";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import Footer from "../container/Footer";
import Header from "../container/Header";
import { DrawerProvider } from "../context/drawer.context";
import { theme } from "../lib/theme";

const GlobalStyle = createGlobalStyle`
  html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    font-family: 'Segoe UI', '-apple-system','Roboto','Helvetica','Arial','Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol', sans-serif; 
    text-rendering: optimizeLegibility;
    color: ${props => props.theme.colors.default};
    background: rgb(250,250,250);
    letter-spacing: 1px;
  }

  strong {
    color: ${props => props.theme.colors.primary};
  }

  * {
    box-sizing: border-box;
  }

  .sticky-nav-active {
    .saas_navbar {
      background: #fff;
      box-shadow: 0px 3px 8px 0px rgba(43, 83, 135, 0.08);
      padding: 0.6rem 0;
      .main-logo {
        display: none;
      }
      .logo-alt {
        display: block;
      }
      .main_menu {
        li {
          a {
            font-weight: bold;
            position: relative;
            transition: 0.15s ease-in-out;
            color: ${props => props.theme.colors.primary};
            &:hover {
              &:before {
                transform: scaleX(1);
                transform-origin: left center 0;
                transition: transform 0.35s cubic-bezier(0.43, 0.49, 0.51, 0.68);
              }
            }
            &:before{
              content: '';
              position: absolute;
              width: calc(100% - 8px);
              height: 11px;
              background: ${props => props.theme.colors.warning};
              bottom: 2px;
              left: -4px;
              z-index: -1;
              transform: scaleX(0);
              transform-origin: right center 0;
              transition: transform 0.7s cubic-bezier(0.19, 1, 0.22, 1) 0s;
            }
            &.is-current {
              &:before {
                transform: scaleX(1);
                transform-origin: left center 0;
                transition: transform 0.35s cubic-bezier(0.43, 0.49, 0.51, 0.68);
              }
            }
          }
        } 
      }
    }
    .hamburgMenu__bar {
      > span {
        background: ${props => props.theme.colors.primary};
      }
    }
  }
  
  .drawer-content {
    background: ${props => props.theme.colors.background};
    ul {
      margin-top: 2.5rem;
      list-style: none;

      li {
        padding: 1rem 0;
        a {
          color: ${props => props.theme.colors.default};
          text-decoration: none;
          font-weight: bold;

          &:hover {
            color: ${props => props.theme.colors.info};
          }
        }
      }
    }
  }

  .reuseModalHolder {
    padding: 30px 15px !important;
  }

  .reuseModalHolder .city-modal {
    background-color: white !important;
  }

  .reuseModalOverlay {
    background-color: rgba(0,0,0,0.5) !important;
  }

  .reuseModalHolder.city-modal .innerRndComponent{
    overflow-y: auto;
  }

  .city-modal {
    border-radius: 8px;
  }
`;

type Props = {
  transparentHeader?: boolean;
};

const Layout: FC<Props> = ({ children, transparentHeader = true }) => {
  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyle />
        <Modal />
        <Sticky top={0} innerZ={1001} activeClass="sticky-nav-active">
          <DrawerProvider>
            <Header transparent={transparentHeader} />
            {/* <Header /> */}
          </DrawerProvider>
        </Sticky>

        <main>{children}</main>

        <Footer />
      </>
    </ThemeProvider>
  );
};

export default Layout;
