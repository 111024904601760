import React, { FC } from "react";
import NavbarStyle, { NavbarProps } from "./navbar.style";

export type Props = {
  className?: string;
  navbarStyle?: any;
};

const Navbar: FC<Props & NavbarProps> = ({
  className,
  children,
  navbarStyle,
  ...props
}) => {
  // Add all classs to an array
  const addAllClasses = ["header__navbar"];

  // className prop checking
  if (className) {
    addAllClasses.push(className);
  }

  return (
    <NavbarStyle className={addAllClasses.join(" ")} {...props}>
      {children}
    </NavbarStyle>
  );
};

/** Navbar default proptype */
Navbar.defaultProps = {};

export default Navbar;
