import React, { FC } from "react";
import Box, { BoxProps } from "../../components/elements/Box";
import { contacts } from "../../lib/config";
import { SocialIcon } from "./socialIconBar.style";

interface SocialIconBarProps extends BoxProps {
  light?: boolean;
  size?: number;
}

const SocialIconBar: FC<SocialIconBarProps> = ({
  light,
  size = 22,
  width = [1]
}) => {
  return (
    <Box flexBox justifyContent="space-around" width={width}>
      {Object.keys(contacts).map(
        (k, i) =>
          contacts[k].link && (
            <a
              href={contacts[k].link}
              key={`social-icon-${i}`}
              rel="noreferrer"
              target="_blank"
            >
              <SocialIcon
                data-light={light}
                icon={contacts[k].icon}
                size={size}
              />
            </a>
          )
      )}
    </Box>
  );
};

export default SocialIconBar;
