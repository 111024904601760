import styled from "styled-components";

const FooterWrapper = styled.footer`
  background: ${props => props.theme.colors.primary};
  color: white;
  padding: 4rem 0;
  font-size: 0.8rem;

  .footer__contact {
    text-decoration: none;
    color: white;
  }
`;

export default FooterWrapper;
