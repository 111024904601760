import { DefaultTheme } from "styled-components";

export const colors = {
  // primary: "#0D5EAF",
  primary: "#005eb2",
  secondary: "#EAF1F7",
  success: "#2dce89",
  info: "#539DD4",
  warning: "#F1CF22",
  danger: "#f5365c",
  default: "#32325d",
  // border: "rgb(241, 244, 246)"
  border: "rgb(235, 235, 235)",
  background: "rgb(250,250,250)"
};

export const theme: DefaultTheme = {
  space: [
    "0",
    "0.5rem",
    "1rem",
    "1.5rem",
    "2rem",
    "2.5rem",
    "3rem",
    "4rem",
    "5rem",
    "6rem",
    "7rem",
    "8rem"
  ],
  // breakpoints: ["36em", "48em", "62em", "75em"],
  breakpoints: ["36em", "48.5em", "64.5em", "75em"],
  colors,
  fontSizes: [12, 14, 16, 20, 24, 28, 32, 48, 64],
  fonts: {
    sans: "Segoe UI, sans-serif",
    mono: "Arial, monospace"
  },
  shadows: {
    small: "0px 8px 20px 0px rgba(16, 66, 97, 0.07)",
    large: "0 0 24px rgba(0, 0, 0, .125)",
    normal: "0px 5px 60px 0px rgba(27, 67, 111, 0.15)"
  },
  headerHeight: "70px",

  buttons: {
    primary: {
      color: "#fff",
      backgroundColor: colors.primary,
      cursor: "pointer"
    },
    light: {
      backgroundColor: "white",
      color: colors.primary
    },
    outline: {
      color: colors.primary,
      backgroundColor: "transparent",
      boxShadow: "inset 0 0 0 2px"
    },
    small: {
      padding: "0.8rem 1rem"
    },
    tiny: {
      padding: "0.5rem 0.8rem"
    }
  },

  cards: {
    rounded: {
      borderRadius: 12,
      border: "1px solid #e6e6e6"
    }
  }
};

const size = {
  xsmall: { min: 0, max: 599 },
  small: { min: 600, max: 779 },
  medium: { min: 780, max: 979 },
  large: { min: 980, max: 1279 },
  xlarge: { min: 1280, max: 1339 },
  xxlarge: { min: 1340, max: Infinity },

  // Sidebar/nav related tweakpoints
  largerSidebar: { min: 1100, max: 1339 },
  sidebarFixed: { min: 2000, max: Infinity },

  // Topbar related tweakpoints
  expandedSearch: { min: 1180, max: Infinity }
};

export const media = {
  between(
    smallKey: keyof typeof size,
    largeKey: keyof typeof size,
    excludeLarge: boolean = false
  ) {
    if (excludeLarge) {
      return `@media (min-width: ${
        size[smallKey].min
      }px) and (max-width: ${size[largeKey].min - 1}px)`;
    } else {
      if (size[largeKey].max === Infinity) {
        return `@media (min-width: ${size[smallKey].min}px)`;
      } else {
        return `@media (min-width: ${size[smallKey].min}px) and (max-width: ${size[largeKey].max}px)`;
      }
    }
  },

  greaterThan(key: keyof typeof size) {
    return `@media (min-width: ${size[key].min}px)`;
  },

  lessThan(key: keyof typeof size) {
    return `@media (max-width: ${size[key].min - 1}px)`;
  },

  size(key: keyof typeof size) {
    if (size[key].min == null) {
      return media.lessThan(key);
    } else if (size[key].max == null) {
      return media.greaterThan(key);
    } else {
      return media.between(key, key);
    }
  }
};
