/** this is our Base Component every components must be Extend it */
import {
  alignSelf,
  AlignSelfProps,
  borderColor,
  BorderColorProps,
  borders,
  BordersProps,
  color,
  ColorProps,
  compose,
  display,
  DisplayProps,
  flex,
  FlexProps,
  fontSize,
  FontSizeProps,
  height,
  HeightProps,
  maxHeight,
  MaxHeightProps,
  maxWidth,
  MaxWidthProps,
  minHeight,
  MinHeightProps,
  minWidth,
  MinWidthProps,
  order,
  OrderProps,
  space,
  SpaceProps,
  width,
  WidthProps
} from "styled-system";

export const themed = key => props => props.theme[key];

export const base = compose(
  () => ({ boxSizing: "border-box" }),
  space,
  width,
  minWidth,
  maxWidth,
  height,
  minHeight,
  maxHeight,
  fontSize,
  color,
  flex,
  order,
  alignSelf,
  borders,
  borderColor,
  display
);

export interface BaseProps
  extends SpaceProps,
    WidthProps,
    MinWidthProps,
    MaxWidthProps,
    HeightProps,
    MinHeightProps,
    MaxHeightProps,
    FontSizeProps,
    ColorProps,
    FlexProps,
    OrderProps,
    AlignSelfProps,
    BordersProps,
    BorderColorProps,
    DisplayProps {}
