import { FlexProps } from "rebass";
import styled from "styled-components";
import {
  display,
  DisplayProps,
  flex,
  height,
  HeightProps,
  maxWidth,
  MaxWidthProps,
  space,
  SpaceProps,
  width,
  WidthProps
} from "styled-system";

const HeaderWrapper = styled("header")<{ transparent?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: 0.35s ease-in-out;
  background: ${props => (props.transparent ? "transparent" : "white")};
  padding: 1.2rem 0;
  .logo-alt {
    display: none;
  }

  .is-current {
    // color: rgb(255, 148, 147) !important;
    // a {
    &:before {
      transform: scaleX(1);
      transform-origin: left center 0;
      transition: transform 0.35s cubic-bezier(0.43, 0.49, 0.51, 0.68);
    }
    // }
  }

  .main_menu {
    li {
      display: inline-block;
      margin: 0 0.5rem;
      a {
        &.is-current {
          &:before {
            transform: scaleX(1);
            transform-origin: left center 0;
            transition: transform 0.35s cubic-bezier(0.43, 0.49, 0.51, 0.68);
          }
        }
        text-decoration: none;
        font-weight: bold;
        position: relative;
        color: ${props =>
          props.transparent ? "#fff" : props.theme.colors.primary};
        transition: 0.15s ease-in-out;
        &:hover {
          &:before {
            transform: scaleX(1);
            transform-origin: left center 0;
            transition: transform 0.35s cubic-bezier(0.43, 0.49, 0.51, 0.68);
          }
        }
        &:before {
          content: "";
          position: absolute;
          width: calc(100% - 8px);
          height: 11px;
          background: ${props =>
            props.transparent
              ? props.theme.colors.info
              : props.theme.colors.warning};
          bottom: 2px;
          left: -4px;
          z-index: -1;
          transform: scaleX(0);
          transform-origin: right center 0;
          transition: transform 0.7s cubic-bezier(0.19, 1, 0.22, 1) 0s;
        }
      }
    }
  }
`;

export const LogoBox = styled("div")<
  SpaceProps & WidthProps & HeightProps & MaxWidthProps
>`
  ${space}
  ${width}
  ${height}
  ${maxWidth}
`;

export const Menu = styled("nav")<FlexProps & DisplayProps>`
  ${display}
  ${width}
  ${flex}
`;

Menu.defaultProps = {
  width: [1],
  justifyContent: "flex-end",
  flexWrap: "nowrap"
};

export default HeaderWrapper;
